<template>
  <v-card
    max-width="400"
  >
    <v-card-title v-translate>
      Push notifications
    </v-card-title>

    <v-card-subtitle v-translate>
      With push notifications you can get realtime information about your project.
    </v-card-subtitle>

    <v-divider
      class="mt-1 mb-2"
    />

    <template
      v-if="supportsWebPush"
    >
      <template
        v-if="activeSubscription"
      >
        <v-subheader v-translate>
          Notification types
        </v-subheader>

        <v-list
          subheader
          two-line
          dense
        >
          <v-list-item
            v-for="(subscriptionType, i) in subscriptionTypes"
            :key="i"
            @click="() => onChange(subscriptionType)"
          >
            <v-list-item-action>
              <v-checkbox :input-value="subscriptionType.subscriptionId" />
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>
                {{ subscriptionType.title }}
              </v-list-item-title>

              <v-list-item-subtitle>
                {{ subscriptionType.subtitle }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider
          class="mt-1 mb-2"
        />
      </template>

      <template
        v-if="permission === 'denied'"
      >
        <v-card-text>
          <p
            v-translate
            class="error--text font-italic subtitle-2"
          >
            You have blocked notifications
          </p>

          <p
            v-translate
            class="caption backgroundAccent--text text--darken-2 mb-0"
          >
            You can enable notifications through your browser's settings.
            Ask your foreman for more information.
          </p>
        </v-card-text>
      </template>

      <template
        v-else
      >
        <v-subheader v-translate>
          Devices
        </v-subheader>

        <v-list
          subheader
          dense
          class="mx-6 pb-4"
        >
          <v-subheader
            v-translate
            class="ml-2 mb-n2"
          >
            This device
          </v-subheader>

          <v-list-item
            v-if="activeSubscription"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ parseUserAgent().title }}
              </v-list-item-title>

              <v-list-item-subtitle>
                {{ parseUserAgent().subtitle }}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                :disabled="$wait.is('unsubscribing from push notifications')"
                :loading="$wait.is('unsubscribing from push notifications')"
                icon
                x-small
                @click="unsubscribeWithOptions"
              >
                <v-icon
                  color="error"
                  small
                >
                  delete
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <template
            v-else
          >
            <v-list-item
              class="text-center"
            >
              <v-list-item-content>
                <v-btn
                  v-translate
                  :disabled="$wait.is('subscribing to push notifications')"
                  :loading="$wait.is('subscribing to push notifications')"
                  depressed
                  color="info darken-1"
                  @click="subscribeToPushNotifications"
                >
                  Subscribe
                </v-btn>

                <p
                  v-translate
                  class="mt-2 mb-0 caption-xs"
                >
                  Your browser will ask permission to send you notifications
                </p>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-subheader
            v-translate
            class="mt-6 ml-2 mb-n2"
          >
            Other devices
          </v-subheader>

          <template
            v-if="otherPushSubscriptions.length > 0"
          >
            <v-list-item
              v-for="(subscription, i) in otherPushSubscriptions"
              :key="i"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ parseUserAgent(subscription.userAgent).title }}
                </v-list-item-title>

                <v-list-item-subtitle>
                  {{ parseUserAgent(subscription.userAgent).subtitle }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn
                  :disabled="$wait.is(`destroying push subscription - ${subscription.id}`)"
                  :loading="$wait.is(`destroying push subscription - ${subscription.id}`)"
                  icon
                  x-small
                  @click="() => destroyPushSubscription(subscription)"
                >
                  <v-icon
                    color="error"
                    small
                  >
                    delete
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>

          <v-list-item
            v-else
          >
            <v-list-item-content>
              <v-list-item-title v-translate>
                No other devices
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </template>

    <v-card-text
      v-else
      v-translate
      class="error--text font-italic"
    >
      Your browser does not support push notifications.
      If you have an Android device, please download Chrome
      browser to get access to notifications.
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';

  const UserAgentParser = require('ua-parser-js');

  export default {
    computed: {
      ...mapGetters({
        supportsWebPush: 'sw/supportsWebPush',
        activeSubscription: 'sw/activeSubscription',
        currentUser: 'currentUser/user',
        permission: 'sw/permission',
        notificationSubscriptions: 'project/notificationSubscriptions/notificationSubscriptions',
      }),

      otherPushSubscriptions() {
        if (!this.activeSubscription) return this.currentUser.pushSubscriptions;

        /**
         * Excludes the push subscription that belongs to this device.
         */
        return this
          .currentUser
          .pushSubscriptions
          .filter(p => p.endpoint !== this.activeSubscription.endpoint);
      },

      subscriptionTypes() {
        const notificationTypes = [
          {
            title: this.$pgettext('Notification type', 'Interruptions'),
            subtitle: this.$pgettext('Notification type subtitle', 'Sends a notification when a work task is interrupted'),
            type: 'webpush_on_interruption',
          },
        ];

        return notificationTypes.map((type) => {
          const subscription = this
            .notificationSubscriptions
            .find(ns => ns.notificationType === type.type);

          if (subscription) {
            type.subscriptionId = subscription.id; // eslint-disable-line
          }

          return type;
        });
      },
    },

    created() {
      this.loadNotificationSubscriptions();
    },

    methods: {
      ...mapActions({
        subscribeToPushNotifications: 'sw/subscribeToPushNotifications',
        unsubscribePushNotification: 'sw/unsubscribePushNotification',
        updateUser: 'currentUser/updateUser',
      }),

      ...mapWaitingActions('project/notificationSubscriptions', {
        loadNotificationSubscriptions: 'loading notification subscriptions',
        createNotificationSubscription: 'creating notification subscriptions',
        destroyNotificationSubscription: 'destroying notification subscriptions',
      }),

      /**
       * Acts as toggle.
       *
       * @param subscription
       */
      onChange(subscription) {
        if (subscription.subscriptionId) {
          this.destroyNotificationSubscription({ id: subscription.subscriptionId });
        } else {
          const payload = {
            deliveryType: 'web_push',
            notificationType: subscription.type,
            userId: this.$currentUser.id,
          };

          this.createNotificationSubscription({ payload });
        }
      },

      async unsubscribeWithOptions() {
        const success = await this.unsubscribePushNotification();
        if (!success) return;
        await this.deleteNotificationSubscriptions();
      },

      async deleteNotificationSubscriptions() {
        /**
         * Destroys notification subscriptions if
         * all push subscriptions are destroyed.
         */
        if (this.currentUser.pushSubscriptions.length <= 0) {
          this.notificationSubscriptions.forEach((ns) => {
            this.destroyNotificationSubscription({ id: ns.id });
          });
        }
      },

      async destroyPushSubscription({ id }) {
        this.$wait.start(`destroying push subscription - ${id}`);

        const payload = {
          user: {
            pushSubscriptionsAttributes: [
              {
                id,
                _destroy: true,
              },
            ],
          },
        };

        await this.updateUser(payload);
        await this.deleteNotificationSubscriptions();

        this.$wait.end(`destroying push subscription - ${id}`);
      },

      parseUserAgent(uaString = navigator.userAgent) {
        const ua = new UserAgentParser(uaString);
        const {
          name: browserName,
        } = ua.getBrowser();
        const {
          name: osName,
        } = ua.getOS();

        return {
          title: `${browserName}`,
          subtitle: `${osName}`,
        };
      },
    },
  };
</script>
